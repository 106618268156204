import { fontFamilies, typographyStyles } from '@/components/ds/Text/typographyStyles'
import styled from 'styled-components'
import { BREAKPOINTS } from '../breakpoints'

export type FontFamilyType = keyof typeof fontFamilies
export type TextVariantType = keyof typeof typographyStyles

// These should match the "colour-text" tokens in colourStyles.ts
export type TextColourType =
  | 'primary'
  | 'primary-inverse'
  | 'secondary'
  | 'secondary-inverse'
  | 'error'
  | 'placeholder'
  | 'link'
  | 'link-hover'
  | 'link-inverse'
  | 'link-inverse-hover'
  | 'link-navigation-bold'
  | 'link-navigation-bold-hover'

export type HTMLTextElement = 'p' | 'span' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 's' | 'div'
export type TextAlignType = 'left' | 'center' | 'right' | 'justify' | 'inherit'
export type TextDecorationType = 'overline' | 'line-through' | 'underline' | 'underline overline'

const StyledTextElement = styled.div<{
  $variant: TextVariantType
  $mobileVariant?: TextVariantType
  $colour: TextColourType
  $weight?: FontFamilyType
  $align?: TextAlignType
  $mobileAlign?: TextAlignType
  $textDecoration?: TextDecorationType
  $maxWidth?: number
}>`
  ${({ $variant }) => typographyStyles[$variant]()}
  ${({ $weight }) => $weight && fontFamilies[$weight]()}

  color: ${({ $colour }) => `var(--colour-text-${$colour})`};
  text-align: ${({ $align }) => $align};
  text-decoration: ${({ $textDecoration }) => $textDecoration};
  ${({ $maxWidth }) => $maxWidth && `max-width: ${$maxWidth}px`};

  a {
    font-family: var(--font-sans-serif-semibold), var(--font-fallback-sans-serif);
  }

  @media (max-width: ${BREAKPOINTS.m}) {
    ${({ $mobileVariant }) => $mobileVariant && typographyStyles[$mobileVariant]()}
    ${({ $weight }) => $weight && fontFamilies[$weight]()}
    ${({ $mobileAlign }) => $mobileAlign && `text-align: ${$mobileAlign}`};
  }
`

type TextProps = {
  as?: HTMLTextElement
  variant: TextVariantType
  mobileVariant?: TextVariantType
  colour?: TextColourType
  weight?: FontFamilyType
  textDecoration?: TextDecorationType
  maxWidth?: number
  align?: TextAlignType
  mobileAlign?: TextAlignType
  testId?: string
  children: React.ReactNode
  id?: string
  className?: string
}

export const DSText = ({
  as = 'p',
  colour = 'primary',
  align = 'inherit',
  mobileAlign = 'inherit',
  variant,
  mobileVariant = variant, // Assign the value of `variant` to `mobileVariant` if it is not provided
  weight,
  textDecoration,
  maxWidth,
  children,
  testId,
  id,
  ...props
}: TextProps) => {
  return (
    <StyledTextElement
      as={as}
      $variant={variant}
      $colour={colour}
      $weight={weight}
      $align={align}
      $textDecoration={textDecoration}
      $maxWidth={maxWidth}
      data-testid={testId}
      $mobileVariant={mobileVariant}
      $mobileAlign={mobileAlign || align}
      id={id}
      {...props}
    >
      {children}
    </StyledTextElement>
  )
}

export const DSTextStrong = ({
  as = 'p',
  colour = 'primary',
  align = 'inherit',
  mobileAlign = 'inherit',
  variant,
  mobileVariant,
  weight,
  textDecoration,
  children,
  ...props
}: TextProps) => {
  return (
    <StyledTextElement
      as={as}
      $variant={variant}
      $colour={colour}
      $weight={weight}
      $align={align}
      $textDecoration={textDecoration}
      $mobileVariant={mobileVariant || variant}
      $mobileAlign={mobileAlign || align}
      {...props}
    >
      <strong>{children}</strong>
    </StyledTextElement>
  )
}

export const DSTextEm = ({
  as = 'p',
  colour = 'primary',
  align = 'inherit',
  mobileAlign = 'inherit',
  variant,
  mobileVariant,
  weight = 'lotaLight',
  textDecoration,
  children,
  ...props
}: TextProps) => {
  return (
    <StyledTextElement
      as={as}
      $variant={variant}
      $colour={colour}
      $weight={weight}
      $align={align}
      $textDecoration={textDecoration}
      $mobileVariant={mobileVariant}
      $mobileAlign={mobileAlign}
      {...props}
    >
      <em>{children}</em>
    </StyledTextElement>
  )
}
