import { Breakpoint, BREAKPOINTS } from '@/components/ds/breakpoints'
import styled, { css } from 'styled-components'
import { DSGridSpacing } from 'styles/ds/gridSpacingStyles'

const StyledSpacer = styled.div<{
  $size: DSGridSpacing
  $breakAt?: SpacerBreakpoints
  $breakpoint?: Breakpoint
  $breakpointSize: DSGridSpacing
  $direction: 'horizontal' | 'vertical'
}>`
  flex: none; // ensures if parent is flexbox, it doesn't grow/shrink

  ${({ $direction, $size, $breakpointSize, $breakpoint }) => {
    if ($direction === 'horizontal') {
      return css`
        width: var(--grid-${$size});
        @media screen and (max-width: ${BREAKPOINTS[$breakpoint || 0]}) {
          width: var(--grid-${$breakpointSize});
        }
      `
    }
    if ($direction === 'vertical') {
      return css`
        width: 100%;
        height: var(--grid-${$size});
        @media screen and (max-width: ${BREAKPOINTS[$breakpoint || 0]}) {
          height: var(--grid-${$breakpointSize});
        }
      `
    }
  }}
}
`

export type SpacerBreakpoints = {
  [key in Breakpoint]?: DSGridSpacing
}

interface SpacerProps {
  size: DSGridSpacing
  direction: 'horizontal' | 'vertical'
  breakAt?: SpacerBreakpoints
}

export const DSSpacer = ({ size, direction, breakAt }: SpacerProps) => {
  const breakpoint = breakAt && Object.keys(breakAt)[0]
  const breakpointSize = breakpoint && breakAt[breakpoint]
  return (
    <StyledSpacer
      $size={size}
      $direction={direction}
      className={`${direction}`}
      $breakAt={breakAt}
      $breakpointSize={breakpointSize}
      $breakpoint={breakpoint as Breakpoint}
    />
  )
}
