import styled from 'styled-components'
import { Breakpoint, BREAKPOINTS } from '@/components/ds/breakpoints'

const StyledHide = styled.div<{ $above?: Breakpoint; $below?: Breakpoint }>`
  ${({ $below }) =>
    $below &&
    `
      @media screen and (max-width: ${BREAKPOINTS[$below]}) {
        display: none;
      }
    `}

  ${({ $above }) =>
    $above &&
    `
      @media screen and (min-width: ${BREAKPOINTS[$above]}) {
        display: none;
      }
    `}
`

interface HidePropsBase {
  children: React.ReactNode
}

interface HidePropsAbove extends HidePropsBase {
  above: Breakpoint
  below?: never
}

interface HidePropsBelow extends HidePropsBase {
  below: Breakpoint
  above?: never
}

type HideProps = HidePropsAbove | HidePropsBelow

export const DSHide = ({ children, above, below }: HideProps) => {
  return (
    <StyledHide $above={above} $below={below}>
      {children}
    </StyledHide>
  )
}
