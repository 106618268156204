import { DSFlex } from '@/components/ds/Flex/Flex'
import { DSSpacer } from '@/components/ds/Spacer/Spacer'
import { DSText } from '@/components/ds/Text/Text'
import styled from 'styled-components'
import { DSGridSpacing } from '../../../styles/ds/gridSpacingStyles'

const StyledDivider = styled.hr<{ $size?: number; $variant?: DividerVariant }>`
  border: none;
  border-top: ${({ $size, $variant }) => `${$size || 1}px solid var(--colour-divider-${$variant || 'primary'});`};
  margin: 0;
  width: 100%;
`

type DividerVariant = 'primary' | 'secondary' | 'tertiary' | 'brand'

interface DividerProps {
  content?: string
  height?: number
  variant?: DividerVariant
  verticalSpacing?: DSGridSpacing
}

export function DSDivider({ content, verticalSpacing, height, variant }: DividerProps) {
  if (!content) {
    return (
      <>
        {verticalSpacing ? <DSSpacer direction="vertical" size={verticalSpacing} /> : null}
        <StyledDivider $size={height} $variant={variant} />
        {verticalSpacing ? <DSSpacer direction="vertical" size={verticalSpacing} /> : null}
      </>
    )
  }

  return (
    <DSFlex alignItems="center" direction="row">
      {verticalSpacing ? <DSSpacer direction="vertical" size={verticalSpacing} /> : null}
      <StyledDivider $variant={variant} />
      <DSSpacer direction="horizontal" size={12} />
      <DSText variant="fluid-label-100">{content}</DSText>
      <DSSpacer direction="horizontal" size={12} />
      <StyledDivider $variant={variant} />
      {verticalSpacing ? <DSSpacer direction="vertical" size={verticalSpacing} /> : null}
    </DSFlex>
  )
}
