import { DSGridSpacing } from 'styles/ds/gridSpacingStyles'
import { default as iconsSprite } from 'public/assets/img/ds/icons/icons.svg'

export type IconName =
  | 'chevron-down'
  | 'chevron-up'
  | 'checkbox-tick'
  | 'close'
  | 'delivery'
  | 'email'
  | 'minus'
  | 'plus'
  | 'radio-selected'
  | 'radio-unselected'
  | 'tick-in-circle-no-fill'
  | 'tick-in-star'
  | 'volume-mute'
  | 'info-circle'
  | 'hand-wave-lines'
  | 'instagram'
  | 'facebook'
  | 'youtube'

export type IconSize = DSGridSpacing

// These should match the "colour-icon" tokens in colourStyles.ts
export type IconColourType = 'primary' | 'secondary' | 'brand' | 'border' | 'inverse' | 'success' | 'lime'

interface DSIconProps {
  name: IconName
  size: IconSize
  colour?: IconColourType
  additionalStyles?: Record<string, any>
}

export const DSIcon = ({ name: iconName, size, colour, additionalStyles = {} }: DSIconProps) => {
  return (
    <svg
      style={{
        width: size,
        height: size,
        minWidth: size,
        minHeight: size,
        color: colour ? `var(--colour-icon-${colour})` : 'var(--colour-text-primary)',
        ...additionalStyles,
      }}
    >
      <use href={`${iconsSprite.src}#${iconName}`} />
    </svg>
  )
}
