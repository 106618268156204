import styled from 'styled-components'
import { Breakpoint, BREAKPOINTS, convertPxToNumber } from '@/components/ds/breakpoints'

const StyledShow = styled.div<{ $above?: Breakpoint; $below?: Breakpoint }>`
  ${({ $below }) =>
    $below &&
    `
      @media screen and (min-width: ${convertPxToNumber(BREAKPOINTS[$below]) + 1}px) {
        display: none;
      }
    `}

  ${({ $above }) =>
    $above &&
    `
      @media screen and (max-width: ${convertPxToNumber(BREAKPOINTS[$above]) - 1}px) {
        display: none;
      }
    `}
`

interface ShowPropsBase {
  children: React.ReactNode
}

interface ShowPropsAbove extends ShowPropsBase {
  above: Breakpoint
  below?: never
}

interface ShowPropsBelow extends ShowPropsBase {
  below: Breakpoint
  above?: never
}

type ShowProps = ShowPropsAbove | ShowPropsBelow

export const DSShow = ({ children, above, below }: ShowProps) => {
  return (
    <StyledShow $above={above} $below={below}>
      {children}
    </StyledShow>
  )
}
